<template>
    <!-- 第一屏 -->
    <swiper class="section swiper-container" ref="mySwiper" :options="swiperOptions">
        <swiper-slide
            v-for="(item, index) in infoData.pictureList"
            :key="index"
            class="screen-1"
            :style="{background: 'url(' + item + ') 0 0  /cover no-repeat'}"
        >
           <!--  <div class="content">
                <div class="title">
                    <div class="main">兴文石海天下奇</div>
                    <div class="desc">- 欢迎来到四川省兴文石海天下奇文化旅游投资有限责任公司 -</div>
                </div>
            </div> -->
        </swiper-slide>
        <div class="swiper-pagination"></div>
    </swiper>
</template>

<script>
// @ is an alias to /src
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    name: 'screen1',
    components: { swiper, swiperSlide },
    props:['infoData'],
    data() {
        return {
            pictureList: ['1', '2'],
            swiperOptions: {
                initialSlide: 0,
                speed: 1000,
                autoplay: true,
                mousewheel: {
                    releaseOnEdges: false //PC端释放滑动
                },
                loop: true,

                // pagination: {
                //     el: '.swiper-pagination', //与slot="pagination"处 class 一致
                //     clickable: true, //轮播按钮支持点击
                //     type: 'custom',
                //     renderCustom: function (swiper, current, total) {
                //         console.log(sw)
                //         var customPaginationHtml = ''
                //         for (var i = 0; i < total; i++) {
                //             //判断哪个分页器此刻应该被激活
                //             if (i == current - 1) {
                //                 customPaginationHtml += '<span class="swiper-pagination-customs swiper-pagination-customs-active"></span>'
                //             } else {
                //                 customPaginationHtml += '<span class="swiper-pagination-customs"></span>'
                //             }
                //         }
                //         return '<span class="swiperPag">' + customPaginationHtml + '</span>'
                //     }
                // }
            }
        }
    },
    methods: {
        goToPage() {
            this.$router.push({ name: 'AboutUs' })
        }
    }
}
</script>
<style lang="less" scoped>
.screen-1 {
    .content {
        width: 100%;
        position: absolute;
        top: 40%;
        .title {
            width: 100%;
            text-align: center;
            color: #fff;
            .main {
                font-size: 80px;
            }
            .desc {
                font-size: 18px;
                margin-top: 20px;
                letter-spacing: 10px;
            }
        }
    }
    .button:hover {
        opacity: 0.9;
    }
}
</style>

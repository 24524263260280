<template>
    <!-- 第五屏 -->
    <div class="section screen-5">
        <div class="warpper">
            <div class="title-box">
                <div class="main">企业党建</div>
                <div class="text">ENTERPRISE PARTY BUILDING</div>
                <span class="more" @click="getParkDetail()">查看更多 ></span>
            </div>
            <div class="main-box">
                <div class="type-list">
                    <div class="box" @click="getParkDetail(4)">
                        <div class="icon-box">
                            <span class="icon icon1"></span>
                        </div>
                        <div class="title">党风廉政</div>
                        <div class="desc">党风廉政相关建设内容</div>
                    </div>
                    <div class="box" @click="getParkDetail(5)">
                        <div class="icon-box">
                            <span class="icon icon2"></span>
                        </div>
                        <div class="title">党建工作</div>
                        <div class="desc" title="三会一课等党建相关的活动内容展示">三会一课等党建相关的活动内容展示</div>
                    </div>
                    <div class="box" @click="getParkDetail(6)">
                        <div class="icon-box">
                            <span class="icon icon3"></span>
                        </div>
                        <div class="title">工会活动</div>
                        <div class="desc">工会活动、志愿者服务等信息</div>
                    </div>
                    <div class="box" @click="getParkDetail(7)">
                        <div class="icon-box">
                            <span class="icon icon4"></span>
                        </div>
                        <div class="title">监督平台</div>
                        <div class="desc">提供监督举报电话</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'screen2',
    components: {},
    data() {
        return {
            infoData: {}
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
          
        },
        // 查看景区详情
        getParkDetail(type) {
            this.$router.push({ name: 'PartyBuilding', params: { type: type } })
        }
    }
}
</script>
<style lang="less" scoped>
.screen-5 {
    background: url('../../../assets/images/home/page5/1.jpg') 50% 50% /cover no-repeat;
    color: #333;
    .warpper {
        width: 1300px;
        margin: 0 auto;
    }
    .title-box {
        text-align: center;
        padding-top: 60px;
        .main {
            font-size: 44px;
        }
        .text {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
        }
        .more {
            display: inline-block;
            margin-top: 80px;
            color: #999999;
            border: 1px solid #999999;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 3px;
        }
        .more:hover {
            color: #ff721d;
            border-color: #ff721d;
        }
        .more:active {
            opacity: 0.7;
        }
    }
    .main-box {
        .type-list {
            display: flex;
            justify-content: space-between;
            margin-top: 80px;
            position: relative;
            .box {
                width: 295px;
                height: 200px;
                border: 1px solid #e8e8e8;
                cursor: pointer;
                padding: 30px;
                .icon-box {
                    .icon {
                        width: 30px;
                        height: 30px;
                        display: block;
                    }
                    .icon1 {
                        background: url('../../../assets/images/icon/6.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                    .icon2 {
                        background: url('../../../assets/images/icon/7.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                    .icon3 {
                        background: url('../../../assets/images/icon/8.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                    .icon4 {
                        background: url('../../../assets/images/icon/9.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                }
                .title {
                    width: 100%;
                    font-size: 20px;
                    color: #333;
                    font-weight: bold;
                    margin-top: 30px;
                }
                .desc {
                    color: #666;
                    width: 100%;
                    margin-top: 30px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
            .box:hover {
                border-color: #ff721d;
            }
        }
    }
}
</style>

<template>
    <!-- 第二屏 -->
    <div class="section screen-2">
        <div class="warpper">
            <div class="title-box">
                <div class="main">关于我们</div>
                <div class="text">ABOUT US</div>
            </div>
            <div class="main-box">
                <div class="img-box">
                    <img src="../../../assets/images/home/page2/3.jpg" alt="" srcset="" />
                    <div class="circle-img"></div>
                </div>
                <div class="content">
                    <pre>{{ common.splitStr(infoData.introduction, 160) }}</pre>
                    <span class="more" @click="getParkDetail()">查看更多 ></span>
                    <div class="title-img"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'screen2',
    components: {},
    props:['infoData'],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        init() {
           
        },
        // 查看景区详情
        getParkDetail() {
            this.$router.push({ name: 'AboutUs' })
        }
    }
}
</script>
<style lang="less" scoped>
.screen-2 {
    background: #fff;
    color: #333;
    .warpper {
        width: 1300px;
        margin: 0 auto;
    }
    .title-box {
        text-align: center;
        padding-top: 60px;
        .main {
            font-size: 44px;
        }
        .text {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
        }
    }
    .main-box {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        position: relative;
        .img-box {
            width: 600px;
            position: relative;
            img {
                width: 600px;
                height: 450px;
            }
             .circle-img {
                width: 120px;
                height: 120px;
                background: url('../../../assets/images/home/page2/2.png') 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                right:-60px;
                bottom: 10px;
            }
        }
        .content {
            width: 600px;
            padding: 100px 0;
            color: #000;
            font-size: 18px;
            pre {
                white-space: pre-wrap;
                word-break: break-all;
            }
            .topic {
                color: #ff721d;
                font-weight: bold;
                margin: 30px 0;
            }
            .more {
                display: inline-block;
                margin-top: 40px;
                color: #999999;
                border: 1px solid #999999;
                font-size: 14px;
                padding: 5px 10px;
                cursor: pointer;
                border-radius: 3px;
            }
            .more:hover {
                color: #ff721d;
                border-color: #ff721d;
            }
            .more:active {
                opacity: 0.7;
            }
            .title-img {
                width: 550px;
                height: 46px;
                background: url('../../../assets/images/home/page2/1.png') 0 0 no-repeat;
                background-size: contain;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-40%, 0);
            }
           
        }
    }
}
</style>

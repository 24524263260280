<template>
    <!-- 第6屏 -->
    <div class="section screen-6">
        <div class="warpper">
            <div class="title-box">
                <div class="main">人力资源</div>
                <div class="text">HUMAN RESOURCES</div>
                <span class="more" @click="getParkDetail()">查看更多 ></span>
            </div>
            <div class="main-box">
                <div class="type-list">
                    <div class="box" @click="getParkDetail('Recruitment')">
                        <div class="img-box">
                            <img src="../../../assets/images/home/temp/5.jpg" alt="" />
                        </div>
                        <div class="title">人才理念</div>
                        <div class="desc">
                            <span>TALENT CONCEPT</span>
                            <span>></span>
                        </div>
                    </div>
                    <div class="box" @click="getParkDetail('Philosophy')">
                        <div class="img-box">
                            <img src="../../../assets/images/home/temp/6.jpg" alt="" />
                        </div>
                        <div class="title">人才招聘</div>
                        <div class="desc">
                            <span>TALENT RECRUITMENT</span>
                            <span>></span>
                        </div>
                    </div>
                    <div class="box" @click="getParkDetail('Groom')">
                        <div class="img-box">
                            <img src="../../../assets/images/home/temp/7.jpg" alt="" />
                        </div>
                        <div class="title">人才培训</div>
                        <div class="desc">
                            <span>TALENT TRAINING</span>
                            <span>></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'screen2',
    components: {},
    data() {
        return {
            infoData: {}
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
         
        },
        // 查看景区详情
        getParkDetail(type) {
            this.$router.push({ name: 'Talents',params:{type:type} })
        }
    }
}
</script>
<style lang="less" scoped>
.screen-6 {
    background: #262930;
    color: #333;
    .warpper {
        width: 1300px;
        margin: 0 auto;
    position: relative;
    }
    .title-box {
        text-align: left;
        padding: 60px 0;
        .main {
            font-size: 44px;
            color: #fff;
        }
        .text {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
        }
        .more {
            display: inline-block;
            margin-top: 40px;
            color: #999999;
            border: 1px solid #999999;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 3px;
        }
        .more:hover {
            color: #ff721d;
            border-color: #ff721d;
        }
        .more:active {
            opacity: 0.7;
        }
    }
    .main-box {
        .type-list {
            display: flex;
            justify-content: space-between;
            margin-top: 80px;
            position: relative;
            .box {
                width: 380px;
                cursor: pointer;
                .title {
                    width: 100%;
                    font-size: 20px;
                    color: #fff;
                    font-weight: bold;
                    margin-top: 50px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-top: 30px;
                    position: relative;
                }
                .title:before {
                    content: '';
                    width: 100%;
                    display: block;
                    position: absolute;
                    top: 0px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                }
                .desc {
                    color: #666;
                    margin-top: 30px;
                }
            }
            
        }
    }
    .bg {
        width: 300px;
        height: 217px;
        background: url('../../../assets/images/home/temp/10.png') 0 0 no-repeat;
        position: absolute;
        top: 30px;
        right: 0;
    }
}
</style>

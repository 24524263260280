var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section screen-6"},[_c('div',{staticClass:"warpper"},[_c('div',{staticClass:"title-box"},[_c('div',{staticClass:"main"},[_vm._v("人力资源")]),_c('div',{staticClass:"text"},[_vm._v("HUMAN RESOURCES")]),_c('span',{staticClass:"more",on:{"click":function($event){return _vm.getParkDetail()}}},[_vm._v("查看更多 >")])]),_c('div',{staticClass:"main-box"},[_c('div',{staticClass:"type-list"},[_c('div',{staticClass:"box",on:{"click":function($event){return _vm.getParkDetail('Recruitment')}}},[_vm._m(0),_c('div',{staticClass:"title"},[_vm._v("人才理念")]),_vm._m(1)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.getParkDetail('Philosophy')}}},[_vm._m(2),_c('div',{staticClass:"title"},[_vm._v("人才招聘")]),_vm._m(3)]),_c('div',{staticClass:"box",on:{"click":function($event){return _vm.getParkDetail('Groom')}}},[_vm._m(4),_c('div',{staticClass:"title"},[_vm._v("人才培训")]),_vm._m(5)])])]),_c('div',{staticClass:"bg"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../../assets/images/home/temp/5.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',[_vm._v("TALENT CONCEPT")]),_c('span',[_vm._v(">")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../../assets/images/home/temp/6.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',[_vm._v("TALENT RECRUITMENT")]),_c('span',[_vm._v(">")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img-box"},[_c('img',{attrs:{"src":require("../../../assets/images/home/temp/7.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc"},[_c('span',[_vm._v("TALENT TRAINING")]),_c('span',[_vm._v(">")])])
}]

export { render, staticRenderFns }
<template>
    <div class="warppe">
        <full-page :options="options" ref="fullpage">
            <home-screen1 :infoData="infoData"></home-screen1>
            <home-screen2 :infoData="infoData"></home-screen2>
            <home-screen3></home-screen3>
            <home-screen4></home-screen4>
            <home-screen5></home-screen5>
            <home-screen6></home-screen6>
        </full-page>
    </div>
</template>

<script>
// @ is an alias to /src
import HomeScreen1 from './pages/Home/Screen_1' 
import HomeScreen2 from './pages/Home/Screen_2' 
import HomeScreen3 from './pages/Home/Screen_3' 
import HomeScreen4 from './pages/Home/Screen_4' 
import HomeScreen5 from './pages/Home/Screen_5' 
import HomeScreen6 from './pages/Home/Screen_6' 
import { getGwCompany } from '@/api/basic'

export default {
    name: 'Home',
    components: {
        HomeScreen1,
        HomeScreen2,
        HomeScreen3,
        HomeScreen4,
        HomeScreen5,
        HomeScreen6,
    },
    data() {
        return {
            options: {
                //整屏插件vue-fullpage选项
                licenseKey: null,
                verticalCentered: false, //定义每一页的内容是否垂直居中
                afterLoad: this.afterLoad, //滚动到某一屏后的回调函数 // scrollOverflow: true,//内容超过满屏后显示滚动条
                controlArrows: true, //是否通过箭头控制slide幻灯片
                loopHorizontal: true, //slide幻灯片是否循环滚动
                scrollBar: true, //true则是一滚动就是一整屏
                onLeave: this.onLeave //滚动前的回调函数，
                // onLeave: this.onLeave, //滚动前的回调函数，
                // afterLoad: this.afterLoad, //滚动到某一屏后的回调函数
            },
            infoData:{},
            
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            getGwCompany().then(res => {
                this.infoData = res.data
            })
        },
       
    }
}
</script>
<style lang="less" scoped>

</style>

<template>
    <!-- 第三屏 -->
    <div class="section screen-4">
        <div class="warpper">
            <div class="title-box">
                <div class="main">国企改革</div>
                <div class="text">REFORM OF STATE-OWNED ENTERPRISES</div>
            </div>
            <div class="main-box">
                <div class="type-list">
                    <div :class="serachParams.type === 1 ? 'type active' : 'type'" @click="getList(1)">公司要闻</div>
                    <div :class="serachParams.type === 2 ? 'type active' : 'type'" @click="getList(2)">行业聚焦</div>
                    <div :class="serachParams.type === 3 ? 'type active' : 'type'" @click="getList(3)">公示公告</div>
                </div>
                <div class="list">
                    <div class="box" v-for="(item,index) in infoData" :key="index" @click="readDetails('news',item)">
                        <div class="img-box">
                            <img :src="item.img" width="325px" height="215px" alt="" />
                            <div class="date">
                                <span class="day">{{moment(item.publishDate || item.createTime).format('DD')}}</span>
                                <span class="month">{{moment(item.publishDate || item.createTime).format('YYYY.MM')}}</span>
                            </div>
                        </div>
                        <div class="info-box">
                            <div class="title" :title="item.title">{{ common.splitStr(item.introduction, 30) }}</div>
                            <div class="desc" :title="item.introduction">
                                {{ common.splitStr(item.introduction, 80) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="more-btn">
                <span class="more" @click="getParkDetail()">查看更多 ></span>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { queryGwNewsList } from '@/api/basic'
import moment from 'moment'
export default {
    name: 'screen4',
    components: {},
    data() {
        return {
            moment,
            infoData: [],
            serachParams: {
                type:1
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            queryGwNewsList(this.serachParams).then(res => {
                this.infoData = res.data
            })
        },
        getList(type){
            this.serachParams.type = type;
            this.init();
        },
        // 查看景区详情
        getParkDetail() {
            this.$router.push({ name: 'CompanyReform',params:{type:this.serachParams.type} })
        },
        readDetails(type,item){
            if(item.url){
                window.open(item.url)
            }else{
                let id = item.id
                this.$router.push({ name: 'Details',params:{type:type,id:id} })
            }
        }
    }
}
</script>
<style lang="less" scoped>
.screen-4 {
    background: url('../../../assets/images/home/page4/1.jpg') 50% 50% no-repeat;
    color: #333;
    .warpper {
        width: 1300px;
        margin: 0 auto;
    }
    .title-box {
        text-align: center;
        padding-top: 60px;
        .main {
            font-size: 44px;
        }
        .text {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
        }
    }
    .main-box {
        .type-list {
            width: 260px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            margin-top: 60px;
            .type {
                padding-bottom: 20px;
                position: relative;
                cursor: pointer;
            }
            .type.active:before {
                content: '';
                width: 30px;
                height: 4px;
                background: #ff721d;
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -15px;
            }
        }
        .list {
            display: flex;
            margin-top: 60px;
            .box {
                width: 325px;
                cursor: pointer;
                .img-box {
                    position: relative;
                    width: 325px;
                    overflow: hidden;
                    img {
                        transition: all 0.5s;
                    }
                }
                .date {
                    width: 65px;
                    height: 60px;
                    text-align: center;
                    background: rgba(0, 0, 0, 0.4);
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: #fff;
                    .day {
                        display: block;
                        font-weight: bold;
                        margin-top: 8px;
                    }
                    .month {
                        display: block;
                        font-size: 14px;
                    }
                }
                .info-box {
                    .title {
                        padding: 20px 10px;
                        color: #333;
                        font-weight: bold;
                        font-size: 18px;
                    }
                    .desc {
                        padding: 0 20px;
                        color: #666;
                    }
                }
            }
            .box:hover {
                .info-box {
                    background: #ff721d;
                    color: #fff;
                }
                .title {
                    color: #fff;
                }
                .desc {
                    color: #fff;
                }
                .date {
                    background: #ff721d;
                }
                img {
                    transform: scale(1.3);
                }
            }
        }
    }
    .more-btn {
        text-align: center;
        .more {
            display: inline-block;
            margin-top: 40px;
            color: #999999;
            border: 1px solid #999999;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 3px;
        }
        .more:hover {
            color: #ff721d;
            border-color: #ff721d;
        }
    }
}
</style>

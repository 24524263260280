<template>
    <!-- 第三屏 -->
    <div class="section screen-3">
        <div class="warpper">
            <div class="title-box">
                <div class="main">产业布局</div>
                <div class="text">INDUSTRIAL LAYOUT</div>
                <span class="more"  @click="goListPage()">查看更多 ></span>
            </div>
            <div class="main-box">
                <div class="img-box" v-for="(item,index) in infoData" :key="index" @click="goListPage(item.code)">
                    <div class="cover">
                        <img width="400px" height="225px" :src="item.img" alt="" srcset="" />
                        <div class="circle-img">
                            <div class="icon-bg">
                                <span class="icon" :style="{background: 'url(' + item.logo + ') 50% 50%  /contain no-repeat'}"></span>
                            </div>
                        </div>
                    </div>
                    <div class="title">{{item.name}}</div>
                    <div class="desc">
                        {{common.splitStr(item.introduction, 160)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { getGwIndustryNavigation } from '@/api/basic'

export default {
    name: 'screen3',
    components: {},
    data() {
        return {
            infoData: {}
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            getGwIndustryNavigation().then(res => {
                this.infoData = res.data
            })
        },
        // 查看景区详情
        goListPage(code) {
            this.$router.push({ name: 'Industry',params:{code:code} })
        }
    }
}
</script>
<style lang="less" scoped>
.screen-3 {
    background: #fff;
    color: #333;
    .warpper {
        width: 1300px;
        margin: 0 auto;
    }
    .title-box {
        text-align: center;
        padding-top: 60px;
        .main {
            font-size: 44px;
        }
        .text {
            font-size: 18px;
            color: #999;
            margin-top: 10px;
        }
        .more {
            display: inline-block;
            margin-top: 40px;
            color: #999999;
            border: 1px solid #999999;
            font-size: 14px;
            padding: 5px 10px;
            cursor: pointer;
            border-radius: 3px;
        }
        .more:hover {
            color: #ff721d;
            border-color: #ff721d;
        }
        .more:active {
            opacity: 0.7;
        }
    }
    .main-box {
        display: flex;
        justify-content: space-between;
        margin-top: 80px;
        position: relative;
        .img-box {
            width: 400px;
            overflow: hidden;
            cursor: pointer;
            .cover {
                position: relative;
            }
            .title {
                text-align: center;
                margin-top: 50px;
                font-weight: bold;
                font-size: 18px;
            }
            .desc {
                margin-top: 20px;
            }
            .circle-img {
                width: 70px;
                height: 70px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 50%;
                position: absolute;
                left: 50%;
                bottom: -35px;
                transform: translate(-50%);
                .icon-bg {
                    width: 50px;
                    height: 50px;
                    display: block;
                    border-radius: 50%;
                    background: #fff;
                    line-height: 70px;
                    text-align: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    .icon {
                        width: 30px;
                        height: 30px;
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    .icon1 {
                        background: url('../../../assets/images/icon/1.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                    .icon2 {
                        background: url('../../../assets/images/icon/2.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                    .icon3 {
                        background: url('../../../assets/images/icon/3.png') 50% 50% no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
</style>
